import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface MenuState {
  data: any[];
}

const initialState: MenuState = {
  data: [],
};

const scrubListDataSlice = createSlice({
  name: "listData",
  initialState,
  reducers: {
    setListData: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setListData } = scrubListDataSlice.actions;
export default scrubListDataSlice.reducer;
