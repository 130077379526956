import { useState } from "react";
import Modal from "../../atom/modal";
import ComponentMap from "../../atom/componentmap";
import DragDrop from "../../atom/draganddropfile";
import ThemedButton from "../../atom/ThemedButton/themedButton";
import DragAndDropFileUpload from "../../atom/updated_Fileuploader";
import FadeIn from "react-fade-in";
import Notification from "../../atom/Notification";
import ImportNumbersModal from "./importNumbersModal";
import Loader from "../loader";
import { uploadScrubFile } from "../../services/api";
import axios from "axios";
import { getENVData } from "../../config/environment";

function NewListModal(props: any) {
  const { modal, setModal, get_All_Lists } = props;
  const [loader, setLoader] = useState<boolean>(false);
  const [importModal, setImportModal] = useState<boolean>(false);
  const [fileData, setFileData] = useState<any>([]);
  const [fileId, setFileId] = useState("");
  const [listname, setListName] = useState<any>([
    { title: "List Name", name: "listname", value: "" },
  ]);

  const onClickImport = async () => {
    if (!listname[0]?.value) {
      Notification("Required", "File name is required", "danger");
    } else if (fileData === null || fileData?.length === 0) {
      Notification("Required", "File is missing", "danger");
    } else {
      setLoader(true);
      console.log("fileData", fileData);
      uploadScrubFile(fileData)
        .then(async (result: any) => {
          console.log("result", result);
          if (result.data.csv_path) {
            setFileId(result.data.uuid);
            try {
              const response = await axios.get(
                getENVData().baseURL.replace("/v2/", "") + result.data.csv_path,
                {
                  responseType: "blob",
                }
              );

              const blob = new Blob([response.data], { type: "text/csv" });
              const fileData = new File([blob], "file.csv", {
                type: "text/csv",
                lastModified: new Date().getTime(), // you can set a custom timestamp here
              });
              console.log("filePath", fileData);
              setFileData(fileData);
              setTimeout(() => {
                setLoader(false);
                setImportModal(true);
              }, 200);
            } catch (error) {}
          }
        })
        .catch((error: any) => {
          console.log("error", error);
          setLoader(false);
        });
    }
  };

  return (
    <div>
      <Modal
        title={importModal ? "Import Numbers" : loader ? "" : "New List"}
        closeEvent={importModal || !loader ? () => setModal(false) : null}
      >
        <div
          className={`${
            importModal
              ? " w-[calc(100vw-200px)] "
              : loader
              ? "w-[400px]"
              : "w-[500px]"
          } transition-all duration-500`}
        >
          {importModal ? (
            <div>
              <ImportNumbersModal
                fileName={listname[0]?.value || "unknown"}
                get_All_Lists={get_All_Lists}
                fileId={fileId}
                fileData={fileData}
                importModal={importModal}
                setImportModal={setModal}
              />
            </div>
          ) : loader ? (
            <div className="pt-8">
              <Loader />
              <div className="text-center my-3 text-xl">
                Uploading, please wait
              </div>
            </div>
          ) : (
            <FadeIn>
              <div>
                <div className="smallPicker ">
                  <ComponentMap data={listname} setData={setListName} />
                </div>
                <div className="p-2 ">
                  <DragAndDropFileUpload
                    fileData={fileData}
                    setFileData={setFileData}
                  />
                </div>

                {/* <div className="pt-4 pl-2 space-y-2">
                  <p className="text-[#1256FE] cursor-pointer w-fit">
                    Template 1
                  </p>
                  <p className="text-[#1256FE] cursor-pointer w-fit">
                    Template 2
                  </p>
                  <p className="text-[#1256FE] cursor-pointer w-fit">
                    Template 3
                  </p>
                </div> */}

                <div className="float-right pt-12 pb-4 sm:w-[300px]">
                  <ThemedButton
                    children={"Cancel"}
                    theme="secondary"
                    click={() => setModal(false)}
                  />
                  <ThemedButton
                    children={"Import Numbers"}
                    theme="primary"
                    // loading={}
                    click={onClickImport}
                  />
                </div>
              </div>
            </FadeIn>
          )}
        </div>
      </Modal>
    </div>
  );
}
export default NewListModal;
