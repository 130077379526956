import { useLocation, useNavigate } from "react-router-dom";
import FadeIn from "react-fade-in";
import { LeftMenuItems } from "../config/leftMenuItems";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { hasPermission } from "../services/utilities";

const LeftSideBar = (props: any) => {
  const navigate = useNavigate();
  const navigation = useLocation();
  const [path, setPath] = useState<any>([]);
  const [activeMenu, setActiveMenu] = useState<any>([]);

  const permissionsList = useSelector(
    (state: any) => state.Permissions.permissions
  );
  const handleNavigation = (e: any = null, node: any, item: any = {}) => {
    e.stopPropagation();
    if (item?.path) {
      navigate(`app/${node.path}/${item.path}`, { replace: true });
    } else if (node?.childrens) {
      navigate(`app/${node.path}/${node.childrens[0].path}`, { replace: true });
    } else {
      navigate(`app/${node.path}`, { replace: true });
    }
  };
  useEffect(() => {
    var temp: any = [];
    var data = LeftMenuItems["control"].map((x) => ({ ...x }));
    data.map((node: any) => {
      if (node?.childrens) {
        var child: any = [];
        node?.childrens.map((item: any) => {
          if (hasPermission(node.item, item.permission, permissionsList)) {
            child.push(item);
          }
        });
        node.childrens = child;
        if (child.length) {
          temp.push(node);
        }
      } else if (hasPermission(node.item, node.permission, permissionsList)) {
        temp.push(node);
      }
    });
    setActiveMenu(temp);
  }, [permissionsList]);
  useEffect(() => {
    setPath(navigation.pathname.split("/"));
  }, [navigation]);
  return (
    <div className="relative !z-[9]">
      <div
        data-testid="leftSideBar"
        id="menuitems"
        className="z-[99] overflow-visible w-[90px] h-[calc(100vh-125px)] shadow-md bg-[#FFFAF5] rounded-md flex flex-col justify-between gap-2 m-[10px]"
        // id="SideBarProductOwnerRoot"
      >
        <div className="flex flex-col gap-px items-start ">
          <div className="w-full">
            <FadeIn>
              {activeMenu.map((node: any, index: number) => {
                return (
                  <div
                    onClick={(e) => {
                      handleNavigation(e, node);
                    }}
                    id={`menu_${node.displayName}`}
                    className={`${
                      path[2] && path[2] === node.path
                        ? "bg-[#feba12] scale-105 border-r-4 border-r-[#000c39]"
                        : "hover:bg-[#feba1223] hover:scale-105"
                    } group relative  py-3 cursor-pointer z-[1]     `}
                  >
                    <div
                      className={`${
                        path[2] && path[2] === node.path ? "fill-[#000C39]" : ""
                      } item-center flex justify-center content-center text-center`}
                    >
                      {node.icon}
                    </div>
                    <div className="p-1  text-center text-sm">
                      {node.displayName}
                    </div>
                    {node?.childrens && (
                      <div className="hidden group-hover:block px-2 top-0 left-[90px] z-[9999] absolute">
                        <div
                          className={`${
                            node.childrens.length >= 3
                              ? "grid-cols-2 "
                              : "grid-cols-1"
                          } bg-[#FEF4E9] grid   rounded-md gap-4 p-4 w-[248px]`}
                        >
                          {node.childrens.map((item: any, ii: number) => {
                            return (
                              <div
                                onClick={(e) => {
                                  handleNavigation(e, node, item);
                                }}
                                className={`${
                                  node.childrens.length === 3 && ii === 2
                                    ? "col-span-2"
                                    : ""
                                } ${
                                  path[3] && path[3] === item.path
                                    ? "bg-[#feba1223] border-2 border-[#373634]"
                                    : "bg-[#fff] border border-[white] hover:bg-[#feba1223]"
                                } h-[100px] p-2 flex justify-center content-center items-center text-center rounded-lg  `}
                              >
                                {item.displayName}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </FadeIn>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LeftSideBar;
