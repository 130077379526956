import { Backdrop } from "@mui/material";
import React, { useState } from "react";
import Lottiefy from "../../atom/lottie/lottie";
import FadeIn from "react-fade-in";
import TableTopBar from "../modal/tableTopBar";
import Table from "../../atom/table";
import { useEffect } from "react";
import NewListModal from "./newListModal";
import CheckIcon from "@mui/icons-material/Check";
import { getScrubToolLists, performZipCrunch } from "../../services/api";
import {
  EyeIcon,
  TableSortIconDownArrow,
  ZipCrunch,
  ZipCrunchIcon,
} from "../../utils/icons/defaultIcons";
import * as loaderLottie from "../../utils/lottie/loaderLottie.json";
import PaginationButtons from "../../atom/pagination";
import { useLocation, useNavigate } from "react-router-dom";
import { setListData } from "../../toolkit/reducers/scrubListDataSlice";
import { useDispatch } from "react-redux";
import Notification from "../../atom/Notification";

function ScrubTool() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const path = useLocation();
  const [modal, setModal] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [resultNotFound, setResultNotFound] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(1);
  const [perPage, setPerPage] = useState<number>(10);
  const [searchtext, setSearchText] = useState<any>("");
  const [tableData, setTableData] = useState<any>([]);
  const [tableTitles, setTableTitles] = useState<any>([
    {
      key: "name",
      title: "List Name",
      icon: <TableSortIconDownArrow />,
      sort: { direction: "asc", active: false },
    },
    {
      key: "imported_on",
      title: "Imported On",
      type: "time",
      icon: <TableSortIconDownArrow />,
      sort: { direction: "asc", active: false },
    },
    {
      key: "wireless_numbers",
      title: "Wireless Numbers",
      conditions: {
        if: {
          target: "status",
          value: "completed",
        },
        else: {
          icon: <div className="cellLoader"></div>,
        },
      },
      icon: <TableSortIconDownArrow />,
      sort: { direction: "asc", active: false },
    },
    {
      key: "total_numbers",
      title: "Total Numbers",
      conditions: {
        if: {
          target: "status",
          value: "completed",
        },
        else: {
          icon: <div className="cellLoader"></div>,
        },
      },
      icon: <TableSortIconDownArrow />,
      sort: { direction: "asc", active: false },
    },
    {
      key: "status",
      conditions: {
        if: {
          value: "completed",
          target: "childrens",
        },
        else: {
          text: "In Progress",
          type: "bubble",
        },
      },
      title: "",
      type: "action",
      class: "text-right w-[160px]",
      childrens: [
        {
          icon: (
            <div className="mr-2 ">
              <EyeIcon />
            </div>
          ),
          action: {
            action: "view",
          },
        },
        {
          icon: (
            <div className="absolute w-6 -mt-[4px]">
              <ZipCrunch />
            </div>
          ),

          conditions: {
            if: {
              tooltip: "Export completed to ZipCrunch",
              value: "completed",
              target: "zipcrunch_export_status",
              icon: (
                <div className="absolute w-6 -mt-[4px]">
                  <CheckIcon />
                </div>
              ),
            },
            elseIf: {
              tooltip: "Export in progress to ZipCrunch",
              value: "progress",
              target: "zipcrunch_export_status",
              icon: (
                <div className="absolute w-6 -mt-[4px]">
                  <div className="cellLoader"></div>
                </div>
              ),
            },
            else: {
              tooltip: "Export to ZipCrunch",
              type: "action",
            },
          },
          action: {
            action: "zipCrunch",
          },
        },
      ],
    },
  ]);
  const [sort, setSort] = useState<any>({
    key: "imported_on",
    order: "desc",
  });
  const get_All_Lists = (pgNum: number) => {
    setPageNumber(pgNum);
    onHandleFetch({ start: pgNum });
  };

  const onHandleFetch: any = (options: {
    limit?: number;
    match?: string;
    start: number;
    sort_column?: string;
    sort_order?: string;
  }) => {
    setLoader(true);
    getScrubToolLists(
      options.start
        ? (options.start - 1) * perPage + 1
        : (pageNumber - 1) * perPage + 1,
      options.sort_column || sort.key,
      options.sort_order
        ? options.sort_order.toLowerCase()
        : sort.order.toLowerCase(),
      options.match || searchtext,
      options.limit || perPage
    ).then((result: any) => {
      const listData = result?.data?.lists;
      if (!result.success || listData?.length === 0) {
        setLoader(false);
        setResultNotFound(true);
      } else {
        setResultNotFound(false);
      }
      setTableData(listData);
      setLoader(false);
      setPageCount(result?.data?.size);
    });
  };

  const handleChange = (pNUm: number) => {
    get_All_Lists(pNUm);
    setPageNumber(pNUm);
  };

  const handlePerPage = (value: any) => {
    value = parseInt(value);
    setPerPage(value);
    setPageNumber(1);
    onHandleFetch({ limit: value, start: 1 });
  };

  const handleSearch = (match: string) => {
    setPageNumber(1);
    setSearchText(match);
    onHandleFetch({ match: match, start: 1, limit: perPage });
  };
  const handleActions = (action: any, node: any, index: any) => {
    const actionsMap: any = {
      zipCrunch: handleZipCrunch,
      view: handleViewClick,
    };
    const act = actionsMap[action.action];
    if (act) {
      act(action.action, node, index);
    } else {
      console.log("Function not found in actionHandle");
    }
  };
  const handleNewList = () => {
    setModal(true);
  };

  const handleViewClick = (action: any, data: any, index: number) => {
    dispatch(setListData(data));
    navigate(`/app/tools/scrubtool/view`, { replace: true });
  };
  const handleKeyPress = (e: any) => {
    //it triggers by pressing the enter key
    console.log("searchtextsearchtext", e);
    if (e.key.toLowerCase() === "enter" && searchtext !== null) {
      handleSearch(searchtext);
    }
  };
  const handleZipCrunch = (action: any, data: any) => {
    console.log("zipcrunch", data, action);
    performZipCrunch(data.uuid).then((result: any) => {
      if (result.success) {
        Notification("Success", "Zipcrunch started", "success");
        onHandleFetch({ start: pageNumber });
      } else {
        Notification("Error", result.message, "danger");
      }
    });
  };
  const actionHandler = (action: string, data: any, key: any) => {
    console.log("action", action, "data", data, "keyy", key);
    const actionsMap: any = {
      "New List": handleNewList,
      zipCrunch: handleZipCrunch,
      view: handleViewClick,
    };
    const act = actionsMap[action];
    if (act) {
      act(action, data, key);
    } else {
      console.log("Function not found in actionHandle");
    }
  };
  const handleSearchTemp = (value: any) => {
    console.log("valuevaluevalue", value);
    setSearchText(value);
  };
  const onHandleSort = (node: any, orderBy: string) => {
    setSort({ key: node.key, order: orderBy });
    onHandleFetch({ sort_column: node.key, sort_order: orderBy });
  };
  useEffect(() => {
    get_All_Lists(1);
  }, []);
  return (
    <>
      {modal && (
        <NewListModal
          get_All_Lists={get_All_Lists}
          modal={modal}
          setModal={setModal}
        />
      )}

      <div data-testid="provison" className="h-full py-3 flex relative gap-2.5">
        <Backdrop className="!z-[99999999999]" open={loader}>
          <Lottiefy loop={true} json={loaderLottie} height={100} width={100} />
        </Backdrop>
        <div className="h-[calc(100vh-125px)] w-[calc(100vw-120px)] rounded-md overflow-hidden bg-[#ffffff]">
          <FadeIn>
            <div className="pt-3">
              <TableTopBar
                title={"Scrub Tool"}
                button={true}
                handleSearch={handleSearch}
                handleKeyPress={true}
                onhandleKeyPress={handleKeyPress}
                setSearchText={handleSearchTemp}
                buttonText={"New List"}
                actionHandler={actionHandler}
                permission={"tools.scrub_tool.get"}
              />
            </div>

            <Table
              tableData={tableData}
              tableTitles={tableTitles}
              resultNotFound={resultNotFound}
              width={"w-[calc(100vw-122px)] overflow-auto"}
              scrollHeight={"h-[calc(100vh-280px)] overflow-y-auto"}
              handleActions={handleActions}
              onHandleSort={onHandleSort}
              setTableTitles={setTableTitles}
              actionHandler={actionHandler}
            />

            {!resultNotFound && tableData.length > 0 && (
              <PaginationButtons
                pageNumber={pageNumber}
                handleChange={handleChange}
                pageCount={pageCount}
                perPage={perPage}
                handlePerPage={handlePerPage}
              />
            )}
          </FadeIn>
        </div>
      </div>
    </>
  );
}

export default ScrubTool;
