import { useRef } from "react";

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";

import zoomPlugin from "chartjs-plugin-zoom";
import { secondsToHHMMSS } from "../../services/utilities";
import { animate, animations } from "framer-motion";
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  zoomPlugin
);
const BarGraph = (props) => {
  const chartRef = useRef();
  const resetZoom = () => {
    if (chartRef.current) {
      chartRef.current.resetZoom(); // Reset zoom using chart instance
    }
  };

  const handleClick = (event) => {
    console.log("handleClick");
  };

  console.log(props, "props");
  const data = {
    labels: props.labels,
    datasets: [],
  };
  const data2 = {
    labels: props.labels,
    datasets: [],
  };
  if (props?.busiestHour) {
    console.log("fgh", props);

    props?.busiestHour?.forEach((props) => {
      data2.datasets.push({
        label: props.label ? props.label : "",
        data: props.data,
        backgroundColor: props.backgroundColor,
        hoverOffset: 4,
        borderWidth: props.border,
        barPercentage: 5,
        barThickness: 8,
        maxBarThickness: 50,
        minBarLength: 1,
        borderRadius: 1,
        // data: props.data,
      });
    });
  } else {
    console.log("fgh", props);

    data.datasets.push({
      label: props.label ? props.label : "",
      data: props.data,
      backgroundColor: props.color,
      hoverOffset: 4,
      borderWidth: props.border,
      barPercentage: 0.3,
      barThickness: props.data.length > 10 ? 10 : 50,
      maxBarThickness: 50,
      minBarLength: 2,
      borderRadius: 2,
    });
  }
  console.log("checkprops", props);
  const options = {
    // animations: false,

    scales: {
      x: {
        stepSize: 4,
        min: 0,

        max: props.data
          ? props.data.length <= 50
            ? props.data.length
            : 50
          : props.labels
          ? props.labels.length < 30
            ? props.labels.length
            : 30
          : null,
        grid: {
          display: false, // This removes the x-axis gridlines
        },
        ticks: {
          autoSkip: false, // Ensures all labels are shown
          beginAtZero: true,
        },
      },
      y: {
        ticks: {
          callback: (value) =>
            `${props.hhmmss ? secondsToHHMMSS(value) : value} `,
        },
        title: {
          text: "sec",
          display: false,
        },
        grid: {
          display: false, // This removes the x-axis gridlines
        },
      },
    },
    responsive: true,

    maintainAspectRatio: false,
    plugins: {
      datalabels: false,
      legend: {
        position: "top",
        display: props.multipleBar,
      },

      zoom: {
        limits: {
          x: { min: 0, max: data.datasets[0]?.data?.length, minRange: 50 },
        },
        pan: {
          enabled: true, // Enables panning
          mode: "x", // Allow panning only along the x-axis
          threshold: 1, // Lower threshold for better responsiveness
          onPanComplete: () => console.log("Panning completed!"),
        },
        zoom: {
          drag: {
            enabled: false, // Enable drag-to-zoom
          },
          mode: "x", // Zoom only along the x-axis
        },
      },
    },
  };

  return (
    <div className={`${props.class} h-full`}>
      <Bar
        data={props.multipleBar === true ? data2 : data}
        ref={chartRef}
        onClick={handleClick}
        options={options}
      />
      {/* <button
        onClick={resetZoom}
        className={`mt-6 px-4  "ml-6"  py-2  bg-primary text-white rounded `}
      >
        Reset Zoom
      </button> */}
    </div>
  );
};
export default BarGraph;
