import { useEffect, useState, useRef } from "react";
import * as loaderLottie from "../../utils/lottie/loaderLottie.json";
import { Backdrop } from "@mui/material";
import Lottiefy from "../../atom/lottie/lottie";
import FadeIn from "react-fade-in";
import TableTopBar from "../modal/tableTopBar";
import Table from "../../atom/table";
import PaginationButtons from "../../atom/pagination";

function ManageExtension() {
  const [recordAll, setRecordAll] = useState<boolean>(false);
  const [apiLoadingStatus, setApiLoadingStatus] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [tableTitles, setTableTitles] = useState<any>([
    {
      key: "extension",
      title: "Extension",
      sort: { direction: "asc", active: false },
    },
    {
      key: "first_name",
      title: "First Name",
      sort: { direction: "asc", active: false },
    },
    {
      key: "last_name",
      title: "Last Name",
      sort: { direction: "asc", active: false },
    },
    {
      key: "monitor_all",
      title: "Monitor All",
      type: "toggle",
    },
    {
      key: "record_all",
      title: "Record All",
      type: "toggle",
    },
  ]);

  const [dummyData, setDummyData] = useState<any>([
    {
      extension: "123",
      first_name: "test",
      last_name: "test",
      monitor_all: "true",
      record_all: "false",
    },
    {
      extension: "124",
      first_name: "test2",
      last_name: "test2",
    },
    {
      extension: "125",
      first_name: "test2",
      last_name: "test2",
    },
    ,
    {
      extension: "126",
      first_name: "test2",
      last_name: "test2",
    },
    ,
    {
      extension: "127",
      first_name: "test2",
      last_name: "test2",
    },
  ]);

  const getExtensionList = (pNUm: number) => {
    setPageNumber(pNUm);
    onHandleSearch({ pageNumber: pNUm });
  };

  const onHandleSearch = (options: {
    limit?: number;
    searchtext?: string;
    pageNumber?: number;
    sort_column?: string;
    sort_direction?: string;
  }) => {
    setTableData(dummyData);
  };

  useEffect(() => {
    getExtensionList(1);
  }, []);

  return (
    <div
      data-testid="callArchive"
      className=" h-full py-3 flex relative gap-2.5"
    >
      <Backdrop className="!z-[99999999999]" open={apiLoadingStatus}>
        <Lottiefy loop={true} json={loaderLottie} height={100} width={100} />
      </Backdrop>

      <div className=" h-[calc(100vh-125px)] w-[calc(100vw-125px)] overflow-hidden min-w-max rounded-md bg-[#ffffff]">
        <FadeIn>
          <div className="pt-3">
            <TableTopBar
              showDropdownValues={tableTitles}
              // setHideDropdownValues={handleTableTitle}
              title={"Manage Extension"}
              CSV={true}
              search={true}
            />
          </div>

          <Table
            tableTitles={tableTitles}
            setTableTitles={setTableTitles}
            tableData={tableData}
            setTableData={setTableData}
            width={"w-[calc(100vw-122px)] overflow-auto"}
            scrollHeight={"h-[calc(100vh-280px)] overflow-y-auto"}
            // actionHandler={actionHandler}
          />

          {/* {!resultNotFound && tableData.length > 0 && (
                <PaginationButtons
                  pageNumber={pageNumber}
                  handleChange={handleChange}
                  pageCount={pageCount}
                  perPage={perPage}
                  handlePerPage={handlePerPage}
                />
              )} */}
        </FadeIn>
      </div>
    </div>
  );
}

export default ManageExtension;
