const mappingSamples: any = {
  id: "File Number",
  ecollectionsid: "File Number",
  "file number": "File Number",
  "debtor full name": "Full Name",
  debtor_full_name: "Full Name",
  "full name": "Full Name",
  casenumber: "File Number",
  case_number: "File Number",
  fullname: "Full Name",
  full_name: "Full Name",
  "debtor first name": "First Name",
  "first name": "First Name",
  firstname: "First Name",
  "last name": "Last Name",
  lastname: "Last Name",
  "debtor last name": "Last Name",
  p1_namefirst: "First Name",
  p1_namelast: "Last Name",
  "1stphone": "Phone Number",
  "2ndphone": "Phone Number",
  "3rdphone": "Phone Number",
  "4thphone": "Phone Number",
  "5thphone": "Phone Number",
  "6thphone": "Phone Number",
  "7thphone": "Phone Number",
  "8thphone": "Phone Number",
  "phone 1": "Phone Number",
  "phone 2": "Phone Number",
  "phone 3": "Phone Number",
  "phone 4": "Phone Number",
  "phone 5": "Phone Number",
  "phone 6": "Phone Number",
  "phone 7": "Phone Number",
  "phone 8": "Phone Number",
  "phone 9": "Phone Number",
  "phone 10": "Phone Number",
  phone_1: "Phone Number",
  phone_2: "Phone Number",
  phone_3: "Phone Number",
  phone_4: "Phone Number",
  phone_5: "Phone Number",
  phone_6: "Phone Number",
  phone_7: "Phone Number",
  phone_8: "Phone Number",
  phone_9: "Phone Number",
  phone_10: "Phone Number",
  Phone: "Phone Number",
  phonenumber: "Phone Number",
  "phone number": "Phone Number",
  tlo_phone_1: "Phone Number",
  tlo_phone_2: "Phone Number",
  tlo_phone_3: "Phone Number",
  tlo_phone_4: "Phone Number",
  tlo_phone_5: "Phone Number",
  tlo_phone_6: "Phone Number",
  tlo_phone_7: "Phone Number",
  tlo_phone_8: "Phone Number",
  relative_1_ph1: "Phone Number",
  relative_1_ph2: "Phone Number",
  relative_2_ph1: "Phone Number",
  relative_2_ph2: "Phone Number",
  relative_3_ph1: "Phone Number",
  relative_3_ph2: "Phone Number",
  relative_4_ph1: "Phone Number",
  relative_4_ph2: "Phone Number",
  relative_5_ph1: "Phone Number",
};
export default mappingSamples;
