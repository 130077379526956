import FadeIn from "react-fade-in";
import Modal from "../../atom/modal";
import Papa from "papaparse";
import { useEffect, useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useTheme } from "../../theme/themeContext";
import Checkbox from "@mui/material/Checkbox";
import Select from "@mui/material/Select";
import { MenuItem } from "@mui/material";
import Close from "@mui/icons-material/Close";
import ThemedButton from "../../atom/ThemedButton/themedButton";
import mappingSamples from "./mappingSample";
import { addScrubToolList } from "../../services/api";
import Notification from "../../atom/Notification";

function ImportNumbersModal(props: any) {
  const { fileData, setImportModal, fileId, fileName, get_All_Lists } = props;
  const [rows, setRows] = useState<any>([]);
  const [preivew, setPreview] = useState<boolean>(false);
  const [isVisible, setVisible] = useState<boolean>(false);
  const [actualMode] = useState<boolean>(true);
  const [selectedCell, setSelectedCell] = useState<string>("");
  const [showedItems] = useState(8);
  const [mapData, setMapData] = useState<any>({});
  const [availableFields] = useState<any>([
    "File Number",
    "Full Name",
    "First Name",
    "Last Name",
    "Phone Number",
  ]);
  function convertToCamelCase(str: string) {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) =>
        index === 0 ? match.toLowerCase() : match.toUpperCase()
      )
      .replace(/\s+/g, ""); // Remove spaces
  }
  const { theme, getCurrentThemeJSON } = useTheme();
  const [themeJSON, setTheme] = useState<any>({});
  useEffect(() => {
    getCurrentThemeJSON(theme, setTheme);
    if (fileData) {
      Papa.parse(fileData, {
        complete: (result: any) => {
          setRows(result.data); // Set the parsed data
          const template: any = {};
          Object.keys(result.data[0]).forEach((key: any) => {
            // console.log("template ==", key, mappingSamples[key]);
            if (mappingSamples[key.toLowerCase()]) {
              template[key] = {
                checked: true,
                mappedTo: mappingSamples[key.toLowerCase()],
              };
            }
          });
          console.log("template", template);
          setMapData(template);
          // setTimeout(() => {
          setVisible(true);
          // }, 2000);
        },
        header: true, // Treats the first row as column headers
        dynamicTyping: true, // Automatically converts types
        skipEmptyLines: true, // Skip empty lines
      });
    } else {
      // Notification("fil")
    }
  }, []);
  const handleCheck = (e: any, row: any) => {
    if (mapData[row]?.mappedTo !== undefined && mapData[row]?.mappedTo !== "") {
      e.stopPropagation();
      return;
    }
    setMapData({
      ...mapData,
      [row]: {
        ...mapData[row],
        checked: mapData[row]?.checked ? !mapData[row]?.checked : true,
      },
    });
    e.stopPropagation();
  };

  const handleRemove = (e: any, row: any) => {
    setMapData((prevMapData: any) => {
      const newMapData = { ...prevMapData }; // Create a shallow copy of the mapData
      delete newMapData[row]; // Remove the specified row from the object
      return newMapData; // Return the updated mapData without the deleted row
    });
    e.stopPropagation();
  };
  const handleScrub = () => {
    const payload: any = {};
    Object.keys(mapData).forEach((key: any) => {
      if (mapData[key]?.checked) {
        if (payload["maintain"]) payload["maintain"].push(key);
        else payload["maintain"] = [key];
      }
      if (
        mapData[key]?.mappedTo !== undefined &&
        mapData[key]?.mappedTo !== ""
      ) {
        const formatedKey = convertToCamelCase(mapData[key]?.mappedTo);
        if (payload[formatedKey]) {
          payload[formatedKey].push(key);
        } else {
          payload[formatedKey] = [key];
        }
      }
    });
    availableFields.forEach((node: any) => {
      const formatedKey = convertToCamelCase(node);
      if (!payload[formatedKey]) {
        payload[formatedKey] = [];
      }
    });
    payload.name = fileName;
    payload.file = fileId;
    console.log("payloadpayloadpayload", payload);
    if (payload.phoneNumber.length === 0) {
      Notification("Required", "Phone number is not mapped", "danger");
    } else if (
      (payload.firstName.length !== 0 && payload.lastName.length !== 0) ||
      payload.fullName.length !== 0
    ) {
      addScrubToolList(payload)
        .then((result: any) => {
          Notification("Successfully", "Successfuly Submitted", "success");
          get_All_Lists(1);
          setImportModal(false);
        })
        .catch((error: any) => {
          Notification("Please try again", "Something went wrong", "danger");
          console.log("Import failed", error);
        });
    } else {
      Notification("Required", "Name is not mapped", "danger");
    }
  };
  const handleMapField = () => {
    setSelectedCell("");
    setPreview(false);
  };
  return (
    // <Modal
    //   title={"Import Numbers"}
    //   closeEvent={() => {
    //     setImportModal(false);
    //   }}
    // >
    <>
      {/* <div className="absolute top-4 right-2 z-[1]"></div> */}
      <div
        className={`transition-opacity duration-500 ${
          isVisible ? "opacity-100" : "opacity-0"
        } `}
      >
        <FadeIn>
          <div className=" border-[#ccc] pb-2">
            <div className="flex justify-between">
              <div className=" overflow-y-hidden ">
                <div className=" max-h-[64px] overflow-y-auto">
                  {Object.keys(mapData)?.map(
                    (key: any) =>
                      mapData[key]?.checked && (
                        // <td>
                        <div
                          className={`${
                            mapData[key]?.checked
                              ? mapData[key]?.mappedTo !== undefined &&
                                mapData[key]?.mappedTo !== ""
                                ? "bg-[#A2ADFF]"
                                : "bg-[#00000012]"
                              : "bg-[#eee] hover:bg-[#ddd]"
                          } border cursor-pointer rounded-[5px] inline-block mb-1  px-1 mr-1 text-sm  whitespace-nowrap border-[#ccc] `}
                        >
                          <div className="flex pl-1">
                            <div className=" pt-[2px]">{key}</div>
                            <div
                              onClick={(e) => handleRemove(e, key)}
                              className="ml-2 text-sm text-[#666] hover:text-[#222]"
                            >
                              <Close className="scale-75 " />
                            </div>
                          </div>
                        </div>
                      )
                  )}
                </div>
              </div>
            </div>
          </div>
          <>
            <div className=" max-h-[calc(100vh-370px)] overflow-y-auto">
              <table className="table-auto border-collapse mb-2 min-w-full">
                <thead>
                  <tr>
                    {rows[0] &&
                      Object.keys(rows[0])?.map((row: any) => (
                        <th onClick={() => setSelectedCell(row)}>
                          <div
                            className={`${
                              mapData[row]?.checked
                                ? mapData[row]?.mappedTo !== undefined &&
                                  mapData[row]?.mappedTo !== ""
                                  ? "bg-[#A2ADFF]"
                                  : "hover:bg-[#EEE]"
                                : " hover:bg-[#EEE]"
                            }  cursor-pointer mb-1 rounded m-[1px] p-2  whitespace-nowrap  `}
                          >
                            <div className="flex">
                              <div className="">
                                <FormControlLabel
                                  style={{
                                    color: "#FEBA12",
                                    margin: "0px",
                                    marginRight: "5px",
                                  }}
                                  onClick={(e) => handleCheck(e, row)}
                                  onChange={(e: any) => {}}
                                  control={
                                    <Checkbox
                                      checked={
                                        mapData[row]?.checked ||
                                        (mapData[row]?.mappedTo !== undefined &&
                                          mapData[row]?.mappedTo !== "")
                                      }
                                      disabled={
                                        mapData[row]?.mappedTo !== undefined &&
                                        mapData[row]?.mappedTo !== ""
                                      }
                                      style={{
                                        color: "#FEBA12",
                                        padding: "0px",
                                      }}
                                    />
                                  }
                                  label=""
                                />
                              </div>
                              <div>{row}</div>
                            </div>
                          </div>
                        </th>
                      ))}
                  </tr>
                </thead>
                <tbody>
                  {rows
                    ?.slice(1, showedItems + 1)
                    .map((row: any, index: number) => (
                      <tr
                        className={`${index % 2 === 0 ? "bg-[#f5f6ff]" : ""}`}
                        key={index}
                      >
                        {Object.keys(row)?.map(
                          (cellValue: any, index: number) => (
                            <td className="border-y px-2 py-[9px] whitespace-nowrap border-[#ddd] ">
                              <div className="w-full text-[14px] pr-4">
                                {" "}
                                {row[cellValue] || ""}
                              </div>
                            </td>
                          )
                        )}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </>
        </FadeIn>
        <div className="m-1 mt-4">
          <div className="p-2 bg-[#EFEEED] rounded">
            <div>Showing first {showedItems} rows from the imported file</div>
            <div>Total imported rows {rows.length - 1}</div>
          </div>
        </div>
        <div className="flex justify-end pt-2 -mr-1">
          <ThemedButton
            theme="ternary"
            children={"Cancel"}
            click={() => {
              setImportModal(false);
            }}
          />
          <ThemedButton
            theme="secondary"
            children={"Preview"}
            click={() => {
              setPreview(true);
            }}
          />
          <ThemedButton
            theme="primary"
            children={"Scrub Numbers"}
            click={() => {
              handleScrub();
            }}
          />
        </div>
      </div>

      {preivew && (
        <Modal
          title={"Preview"}
          closeEvent={() => {
            setPreview(false);
          }}
        >
          <div className="w-[calc(100vw-300px)] overflow-x-auto">
            <table className="table-auto border-collapse min-w-full">
              <thead>
                <tr>
                  {actualMode &&
                    Object.keys(mapData)?.map(
                      (key: any) =>
                        mapData[key]?.checked && (
                          <th>
                            <div
                              className={` p-1 mr-1  whitespace-nowrap border-[#ccc] `}
                            >
                              <div className="flex pl-1">
                                {key}
                                <div
                                  onClick={(e) => handleRemove(e, key)}
                                  className="pl-2 text-sm text-[#666] hover:text-[red]"
                                ></div>
                              </div>
                            </div>
                          </th>
                        )
                    )}
                </tr>
              </thead>
              <tbody>
                {rows?.slice(0, showedItems + 1).map(
                  (row: any, index: number) =>
                    index > 0 && (
                      <tr
                        className={`${index % 2 === 0 ? "bg-[#f5f6ff]" : ""}`}
                        key={index}
                      >
                        {Object.keys(mapData)?.map(
                          (cellValue: any, index: number) =>
                            mapData[cellValue]?.checked && (
                              <td
                                className={` border-y px-2 py-2 whitespace-nowrap border-[#ddd]`}
                              >
                                <div className="w-full text-[14px] pr-4">
                                  {" "}
                                  {row[cellValue]}
                                </div>
                              </td>
                            )
                        )}
                      </tr>
                    )
                )}
              </tbody>
            </table>
          </div>
          <div className="flex justify-end pt-2 -mr-1">
            <ThemedButton
              theme="secondary"
              children={"Cancel"}
              click={() => {
                setPreview(false);
              }}
            />
            <ThemedButton
              theme="primary"
              children={"Scrub Numbers"}
              click={() => {
                handleScrub();
              }}
            />
          </div>
        </Modal>
      )}
      {selectedCell !== "" && (
        <Modal
          title={"Map " + selectedCell}
          closeEvent={() => {
            setSelectedCell("");
          }}
        >
          <div className="px-1 w-[420px]">
            <FormControlLabel
              style={{
                color: themeJSON?.ternaryText,
                margin: "0px",
                marginRight: "20px",
              }}
              onChange={(e: any) => {
                setMapData({
                  ...mapData,
                  [selectedCell]: {
                    ...mapData[selectedCell],
                    checked: e.target.checked,
                  },
                });
              }}
              control={
                <Checkbox
                  checked={
                    mapData[selectedCell]?.checked ||
                    (mapData[selectedCell]?.mappedTo !== undefined &&
                      mapData[selectedCell]?.mappedTo !== "")
                  }
                  disabled={
                    mapData[selectedCell]?.mappedTo !== undefined &&
                    mapData[selectedCell]?.mappedTo !== ""
                  }
                  style={{
                    color: "#F78F1E",
                    padding: "0px",
                    paddingRight: "8px",
                  }}
                />
              }
              label="Maintain this field"
            />
            <div>
              <div className="pt-3 pb-2 text-[#666]">Select field name</div>
              <div className="flex w-full">
                <div className="border border-[#ccc] p-[3px] pt-[6px] px-3 w-[calc(50%-30px)] rounded">
                  {selectedCell}
                </div>
                <div className="w-[60px]  p-[2px] pt-[4px] flex justify-center">
                  <svg
                    width="36"
                    height="30"
                    viewBox="0 0 36 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M30.8531 12.3531C31.0469 12.1594 31.0469 11.8406 30.8531 11.6469L25.3531 6.1469C25.1594 5.95315 24.8406 5.95315 24.6469 6.1469C24.4531 6.34065 24.4531 6.6594 24.6469 6.85315L29.2937 11.5H17.5C17.225 11.5 17 11.725 17 12C17 12.275 17.225 12.5 17.5 12.5H29.2937L24.6469 17.1469C24.4531 17.3407 24.4531 17.6594 24.6469 17.8531C24.8406 18.0469 25.1594 18.0469 25.3531 17.8531L30.8531 12.3531Z"
                      fill="black"
                    />
                    <path
                      d="M5.14678 17.6469C4.95303 17.8406 4.95303 18.1594 5.14678 18.3531L10.6468 23.8531C10.8405 24.0469 11.1593 24.0469 11.353 23.8531C11.5468 23.6594 11.5468 23.3407 11.353 23.1469L6.70615 18.5H18.4999C18.7749 18.5 18.9999 18.275 18.9999 18C18.9999 17.725 18.7749 17.5 18.4999 17.5H6.70615L11.353 12.8531C11.5468 12.6594 11.5468 12.3406 11.353 12.1469C11.1593 11.9531 10.8405 11.9531 10.6468 12.1469L5.14678 17.6469Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <div className="w-[calc(50%-30px)] relative DDWithClose">
                  <Select
                    size="small"
                    style={{
                      width: "100%",
                    }}
                    labelId="demo-simple-select-label"
                    id="DDWithClose"
                    value={mapData[selectedCell]?.mappedTo || "2"}
                    label={""}
                    // onChange={handleChange}
                  >
                    <MenuItem
                      onClick={() =>
                        setMapData({
                          ...mapData,
                          [selectedCell]: {
                            ...mapData[selectedCell],
                            checked: true,
                            mappedTo: "",
                          },
                        })
                      }
                      value={"2"}
                      key={0}
                    >
                      Select
                    </MenuItem>
                    {availableFields.map((n: any, i: any) => {
                      return (
                        <MenuItem
                          onClick={() =>
                            setMapData({
                              ...mapData,
                              [selectedCell]: {
                                ...mapData[selectedCell],
                                checked: true,
                                mappedTo: n,
                              },
                            })
                          }
                          value={n}
                          key={i}
                        >
                          {n}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {/* {mapData[selectedCell]?.mappedTo && (
                    <div
                      onClick={() => removeMapped(selectedCell)}
                      className="absolute top-2 cursor-pointer right-2 scale-75 hover:text-[#F78F1E] bg-[#FEF7F0]"
                    >
                      <Close />
                    </div>
                  )} */}
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end pt-2 -mr-1">
            <ThemedButton
              theme="secondary"
              children={"Cancel"}
              click={() => {
                setPreview(false);
              }}
            />
            <ThemedButton
              theme="primary"
              children={"Save"}
              click={() => {
                handleMapField();
              }}
            />
          </div>
        </Modal>
      )}
      {/* </Modal> */}
    </>
  );
}
export default ImportNumbersModal;

// const payload = {
//   fileNumber: ["case_number"],
//   fullName: ["debtor_full_name"],
//   firstName: [],
//   lastName: [],
//   phoneNumber: [
//     "tlo_phone",
//     "tlo_phone_2",
//     "tlo_phone_3",
//     "tlo_phone_4",
//     "tlo_phone_5",
//   ],
//   maintain: ["tlo_address"],
// };
