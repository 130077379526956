import React, { useState } from "react";
import { DeleteIcon, FileUploadIcon } from "../utils/icons/defaultIcons";

const DragAndDropFileUpload = (props) => {
  const { fileData, setFileData } = props;
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");

  const allowedFileTypes = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
    "application/vnd.ms-excel", // .xls
    "text/csv", // .csv
  ];
  const maxFileSize = 10 * 1024 * 1024; // 10MB in bytes

  const handleFileValidation = (file) => {
    if (!allowedFileTypes.includes(file.type)) {
      setError("Only .xlsx, .xls, and .csv files are allowed.");
      return false;
    }
    if (file.size > maxFileSize) {
      setError("File size should not exceed 10MB.");
      return false;
    }
    setError(""); // Clear any previous error
    return true;
  };

  const handleFiles = (selectedFiles) => {
    const singleFile = selectedFiles[0];
    if (handleFileValidation(singleFile)) {
      setFile(singleFile);
      setFileData(singleFile);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const droppedFiles = event.dataTransfer.files;
    handleFiles(droppedFiles);
  };

  const handleFileSelect = (event) => {
    const selectedFiles = event.target.files;
    if (selectedFiles.length > 0) {
      handleFiles(selectedFiles);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleRemoveFile = () => {
    setFile(null);
    setFileData(null);
    setError("");
  };

  return (
    <div>
      <div
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        style={{
          width: "100%",
          height: "120px",
          border: "2px dashed #595754",
          borderRadius: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "20px",
          backgroundColor: "#f0f8ff",
          cursor: "pointer",
          position: "relative", // Add relative positioning
          overflow: "hidden", // Prevent content from spilling out
        }}
      >
        <div className="grid place-items-center">
          <FileUploadIcon />
          {file ? <p>{file.name}</p> : <p>Drag and Drop here</p>}
        </div>
        <input
          type="file"
          accept=".xlsx,.xls,.csv"
          style={{
            opacity: 0,
            position: "absolute",
            width: "100%",
            height: "100%",
            cursor: "pointer",
          }}
          onChange={handleFileSelect}
        />
      </div>
      {file && (
        <div>
          <p>
            <b>File Name:</b> {file.name}
          </p>
          <button
            onClick={handleRemoveFile}
            style={{ marginTop: "10px" }}
            className=" hover:underline"
          >
            Remove File
          </button>
        </div>
      )}
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
};

export default DragAndDropFileUpload;
