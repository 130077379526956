import { useEffect, useRef, useState } from "react";
import Table from "../../atom/table";
import FadeIn from "react-fade-in";
import {
  DeleteIcon,
  LineBulletinIcon,
  TableSortIconDownArrow,
} from "../../utils/icons/defaultIcons";
import ComponentMap from "../../atom/componentmap";
import ThemedButton from "../../atom/ThemedButton/themedButton";
import TableTopBar from "../modal/tableTopBar";
import { useDispatch, useSelector } from "react-redux";
import { activeCallRecording } from "../../toolkit/reducers/callRecordingsSlice";
import { motion } from "framer-motion";
import Modal from "../../atom/modal";
import Lottiefy from "../../atom/lottie/lottie";
import * as deleteIcon from "../../utils/lottie/deleteIcon.json";
import * as downloadLottie from "../../utils/lottie/downloadLottie.json";

function CallArchive() {
  const saveNameModalRef = useRef<HTMLInputElement>(null);
  const [saveSearchmodal, setSaveSearchModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [onDeleteRec, setOnDeleteRec] = useState<boolean>(false);
  const [handleKeyPress, setHandleKeyPress] = useState<boolean>(false);
  const [hideDropdownValues, setHideDropdownValues] = useState<any>([
    { name: "Direction", checked: false },
    { name: "Orginal Extension", checked: false },
    { name: "Transfer Extension", checked: false },
    { name: "Groups", checked: false },
    { name: "Call Organization", checked: false },
    { name: "Call Destination", checked: false },
    { name: "Start Time", checked: false },
    { name: "End Time", checked: false },
    { name: "Total Time", checked: false },
  ]);

  const [searchFormData, setSearchFormData] = useState<any>([
    {
      title: "Direction",
      name: "direction",
      value: "",
      type: "dropdown",
      displayProps: { label: "label", value: "uuid" },
      childrens: [
        { label: "Inbound", value: "Inbound" },
        { label: "Outbound", value: "Outbound" },
      ],
    },
    {
      title: "Call Organization",
      name: "callOrganization",
      value: "",
    },
    {
      title: "Ordinal Extension",
      name: "ordinalExtension",
      value: "",
    },
    {
      title: "Call Destination",
      name: "callDestination",
      value: "",
    },
    {
      title: "Transfer Extension",
      name: "transferExtension",
      value: "",
    },
    {
      title: "Start Time",
      name: "startTime",
      type: "dateTimePicker",
      value: "",
    },
    {
      title: "End Time",
      name: "endTime",
      type: "dateTimePicker",
      value: "",
    },
    {
      title: "Groups",
      name: "groups",
      type: "dropdown",
      value: "",
      displayProps: { label: "label", value: "uuid" },
      childrens: [
        { label: "Data1", value: "Data1" },
        { label: "Data2", value: "Data2" },
      ],
    },
  ]);

  const [saveSearchName, setSaveSearchName] = useState<any>([
    {
      title: "Enter name",
      name: "name",
      value: "",
    },
  ]);

  const [savedSearchName, setSavedSearchName] = useState<any>([
    "Group Statistics",
    "Tranfser Filter",
    "Save Search Layout",
    "Outbound Reports",
    "Main Filter",
  ]);

  const tableTitles = [
    { key: "data1", title: "Direction", icon: <TableSortIconDownArrow /> },
    {
      key: "data2",
      title: "Orginal Extension",
      icon: <TableSortIconDownArrow />,
    },
    {
      key: "data3",
      title: "Transfer Extension",
      icon: <TableSortIconDownArrow />,
    },
    { key: "data4", title: "Groups", icon: <TableSortIconDownArrow /> },
    {
      key: "data5",
      title: "Call Orgination",
      icon: <TableSortIconDownArrow />,
    },
    {
      key: "data6",
      title: "Call Destination",
      icon: <TableSortIconDownArrow />,
    },
    { key: "data7", title: "Start Time", icon: <TableSortIconDownArrow /> },
    { key: "data8", title: "End Time", icon: <TableSortIconDownArrow /> },
    { key: "data9", title: "Total Time" },
  ];

  const [tableData, setTableData] = useState<any>([]);

  const onHandleSaveSearchName = (result: any) => {
    let dummyData = [...savedSearchName];
    dummyData?.push(result.name);
    setSavedSearchName(dummyData);
  };

  const onHandleCancel = () => {
    let dummmyData = [...saveSearchName];
    dummmyData[0].value = "";
    setSaveSearchName(dummmyData);
    setSaveSearchModal(false);
  };
  const dispatch = useDispatch();
  const value = useSelector((state: any) => state.Call.callName);
  const activeMenu = useSelector((state: any) => state.menuItem.value);

  const change = (activeData: number) => {
    dispatch(activeCallRecording(activeData));
  };

  const closeDeleteModal = () => {
    setDeleteModal(!deleteModal);
    setOnDeleteRec(false);
  };

  const deleteRecording = () => {
    setOnDeleteRec(!onDeleteRec);
  };

  useEffect(() => {}, [handleKeyPress]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        saveNameModalRef.current &&
        !saveNameModalRef.current.contains!(event.target)
      ) {
        setSaveSearchModal(false);
      }
    };

    // Attach the event listener to the document
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener when the component is unmounted
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [saveSearchmodal]);

  const isFirstRender = useRef(true);

  useEffect(() => {
    isFirstRender.current = false;
    // ---> StrictMode: The following is REQUIRED to reset/cleanup:
    return () => {
      isFirstRender.current = true;
    };
  }, []);

  return (
    <div
      data-testid="callArchive"
      className=" h-full py-3 flex relative gap-2.5"
    >
      {deleteModal && (
        <Modal
          icon={<DeleteIcon />}
          title={"Delete recording"}
          closeEvent={closeDeleteModal}
        >
          {onDeleteRec ? (
            <div className="gap-y-4 flex flex-col">
              <Lottiefy loop={false} json={deleteIcon} height={95} width={95} />

              {/* <Lottiefy
                loop={false}
                json={downloadLottie}
                height={30}
                width={30}
              /> */}
              <div className="flex items-center justify-center">
                Deleted successfully
              </div>
              <div className="text-center -bottom-10 ">
                <ThemedButton
                  children={"Ok"}
                  theme="secondary"
                  click={() => closeDeleteModal()}
                />
              </div>
            </div>
          ) : (
            <>
              <div>Are you sure you want to delete this recording?</div>
              <div className="text-right absolute inset-x-0 bottom-0 mb-2 mr-2">
                <ThemedButton
                  children={"Cancel"}
                  icon="cancel"
                  theme="ternary"
                  click={() => setDeleteModal(!deleteModal)}
                />
                <ThemedButton
                  children={"Delete"}
                  // disabled={childData === "loading" || childData.length !== 0}
                  icon="remove"
                  theme="primary"
                  click={() => {
                    deleteRecording();
                  }}
                />
              </div>
            </>
          )}
        </Modal>
      )}

      {/* Side Search */}
      <div className="h-[calc(100vh-125px)]  w-[260px] bg-[#FFFFFF] rounded-md">
        <div className="font-bold p-4">Search Call Archives</div>

        <div className="smallPicker">
          <ComponentMap data={searchFormData} setData={setSearchFormData} />
        </div>

        <div
          ref={saveNameModalRef}
          className="relative px-3 text-right text-[#8092d8a4] hover:text-[#98A6DD]"
        >
          <a
            className=" cursor-pointer"
            onClick={() => setSaveSearchModal(!saveSearchmodal)}
          >
            Save this search
          </a>
          {saveSearchmodal && (
            <div className="absolute w-[95%] h-fit -ml-1.5 mt-2 rounded-md z-50 smallPicker bg-[#FEF4E9]">
              <ComponentMap data={saveSearchName} setData={setSaveSearchName} />
              <ThemedButton
                children={"Cancel"}
                theme={"ternary"}
                click={onHandleCancel}
              />
              <ThemedButton
                children={"Save"}
                theme={"primary"}
                click={onHandleSaveSearchName}
                valiData={saveSearchName}
                change={setSaveSearchName}
              />
            </div>
          )}
        </div>
        <div className="w-[100%] h-[44px] px-2 mt-3">
          <div className="flex justify-center items-center px-3 bg-primary hover:bg-[#f8a144e2] rounded-md h-full w-full cursor-pointer">
            SEARCH
          </div>
        </div>
        {/* Saved searches div */}
        <div className="h-fit w-[94%] ml-2 rounded-sm mt-5">
          <a className="text-[#898989]"> Saved searches</a>
          <div className="mt-1.5 max-h-[133px] overflow-y-auto">
            {savedSearchName?.map((node: any) => {
              return (
                <div
                  key={node}
                  className="flex items-center mt-2 py-1.5 px-2 gap-2 hover:bg-ternary cursor-pointer rounded-sm hover:transform"
                >
                  <div>
                    <LineBulletinIcon />
                  </div>
                  <div className="max-w-[195px] truncate">{node}</div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/* Search ends here */}

      <div className=" h-[calc(100vh-125px)] w-fit  rounded-md bg-[#FFFFFF]">
        <FadeIn>
          <div className="pt-3">
            <TableTopBar
              hideDropdownValues={hideDropdownValues}
              setHideDropdownValues={setHideDropdownValues}
              title={"Call Archive"}
              CSV={true}
              copy={true}
            />
          </div>
          <Table
            tableTitles={tableTitles}
            tableData={tableData}
            setTableData={setTableData}
            deleteModal={deleteModal}
            setDeleteModal={setDeleteModal}
            audioPlayer={
              "https://www2.cs.uic.edu/~i101/SoundFiles/StarWars60.wav"
            }
            width={"w-[calc(100vw-394px)]"}
            checkBox={true}
            scrollHeight={"max-h-[700px] overflow-y-auto"}
          />
          <b className="flex justify-center items-center mt-[250px]">
            No Results Found!
          </b>
        </FadeIn>
      </div>
    </div>
  );
}

export default CallArchive;
