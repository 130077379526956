import React, { useRef } from "react";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = (props) => {
  const chartRef = useRef();

  const handleClick = (event) => {
    console.log("handleClick");
  };
  const data = {
    labels: props.labels,
    datasets: [
      {
        label: props.label,
        data: props.data,
        backgroundColor: props.color,
        hoverOffset: 4,
        cutout: props?.cutout ? props?.cutout : "70%",
      },
    ],
  };
  const options = {
    plugins: {
      datalabels: {
        formatter: (value, context) => {
          const label = context.chart.data.labels[context.dataIndex];
          return `${label}: ${value}`;
        },
        color: "#000",
        align: "end",
        anchor: "center",
        backgroundColor: "#fff",
        borderColor: "#ccc",
        borderWidth: 1,
        borderRadius: 3,
        padding: 5,
      },

      legend: {
        display: true,
        position: "bottom",
      },
    },
    responsive: true,
    maintainAspectRatio: false,
  };
  return (
    <Doughnut
      data={data}
      ref={chartRef}
      onClick={handleClick}
      options={options}
    />
  );
};
export default DoughnutChart;
