import { useEffect, useState } from "react";
import {
  DeleteIcon,
  DownArrow,
  IncomingCallIcon,
  InternalCallIcon,
  OutgoingCallIcon,
  PhoneIncomingCallIcon,
  UpArrow,
  UserIcon,
} from "../../../utils/icons/defaultIcons";
import FadeIn from "react-fade-in";
import moment from "moment";
import DurationCounter from "../../../atom/timeDuration";

function AgentInfoCard(props: any) {
  const { page, data } = props;

  const [dropdownSelect, setDropdownSelect] = useState<boolean>(true);

  const [agentInfo, setAgentInfo] = useState<any>([
    {
      key: "call_did",
      value: "",
      title: "Caller ID",
    },
    {
      key: "call_start_time",
      value: "",
      title: "Start",
    },
    {
      key: "call_duration",
      value: "",
      title: "Duration",
    },
    // {
    //   value: "",
    //   title: "Call Count",
    // },
    // {
    //   value: "",
    //   title: "Talk Time",
    // },
    // {
    //   value: "",
    //   title: "Avg Talk Time",
    // },
  ]);
  useEffect(() => {
    console.log("dataaaa", data);

    const updatedAgentInfo = agentInfo.map((item: any) => {
      if (item.key === "call_start_time" && data[item.key] !== "") {
        return {
          ...item,
          value: moment(new Date(data[item.key])).format("LTS"),
        };
      }

      if (item.key === "call_duration") {
        return {
          ...item,
          value:
            data.call_start_time !== "" && data.ph_state === "busy" ? (
              <DurationCounter startTime={data.call_start_time} />
            ) : (
              ""
            ),
        };
      }

      return {
        ...item,
        value: data[item.key] !== undefined ? data[item.key] : item.value,
      };
    });

    setAgentInfo(updatedAgentInfo);
  }, [data]);
  // const dropdownselect = () => {
  //   if (data?.ph_state === "busy") setDropdownSelect(!dropdownSelect);
  // };

  return (
    <div
      data-testid="agentInfoCard"
      className={`${page === "main" ? "relative" : ""} shadow hover:shadow-xl ${
        !dropdownSelect && "hover:scale-y-105"
      }`}
    >
      {/* agent box */}

      {/* top bar */}
      <div
        className={`h-[67px] ${
          !data?.is_logged_in
            ? "bg-[#EBEBEB80]"
            : data?.ph_state === "busy"
            ? "bg-[#D14D41]"
            : "bg-[#879A39]"
        }
        } rounded-lg mt-4 group `}
      >
        <div className="flex justify-between p-3">
          <div className="flex gap-2">
            <div>
              <UserIcon />
              <p>{data?.extension}</p>
            </div>
            <div>
              <p>
                {data?.first_name} {data?.last_name}
              </p>
              <div className="flex items-center justify-between gap-x-1 w-fit rounded-lg px-2 bg-[#FBFBFF]">
                <div
                  className={`rounded-full h-[7px] w-[7px] ${
                    !data?.is_logged_in
                      ? "bg-[#7f7b7b]"
                      : data?.ph_state === "busy"
                      ? "bg-[#D14D41]"
                      : "bg-[#879A39]"
                  } 
                  }`}
                ></div>
                <div
                  className={`${
                    !data?.is_logged_in
                      ? "text-[#7f7b7b]"
                      : data?.ph_state === "busy"
                      ? "text-[#D14D41]"
                      : "text-[#879A39]"
                  } 
                  }`}
                >
                  {!data?.is_logged_in
                    ? "Logged Out"
                    : data?.ph_state === "busy"
                    ? "Busy"
                    : "Available"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*  */}

      {dropdownSelect && (
        <div
          className={`h-auto w-full  ${
            page === "main" && "absolute"
          } bg-[#E6F9F4] overflow-visible rounded-lg  
           z-[2]`}
        >
          <FadeIn>
            <div className="grid grid-cols-3 py-1 space-y-4  justify-items-center ">
              {agentInfo?.map((node: any) => {
                return (
                  <div key={node} className="mt-4   px-3">
                    <p className=" text-[18px] font-bold justify-center items-center flex">
                      {node.key === "call_did" && (
                        <div className="mt-0.5 ">
                          {data?.call_direction === "inbound" ? (
                            <IncomingCallIcon />
                          ) : data?.call_direction === "outbound" ? (
                            <OutgoingCallIcon />
                          ) : data?.call_direction === "internal" ? (
                            <InternalCallIcon />
                          ) : null}
                        </div>
                      )}
                      {node.value === "" ? "--" : node.value}
                    </p>
                    <p className=" text-center font-normal text-[#595754]">
                      {/* {node.key === "call_did" && data?.call_direction === ""
                        ? "Caller ID"
                        : data?.call_direction} */}
                      {node.key === "call_did" && data?.call_direction !== ""
                        ? data?.call_direction.charAt(0).toUpperCase() +
                          data?.call_direction.slice(1)
                        : node.key === "call_did" &&
                          data?.call_direction === "" &&
                          "Caller ID"}
                      {node.key !== "call_did" && node.title}
                    </p>
                  </div>
                );
              })}
            </div>
          </FadeIn>
        </div>
      )}

      {/*  */}
    </div>
  );
}

export default AgentInfoCard;
