import FadeIn from "react-fade-in/lib/FadeIn";
import SearchBar from "../../../atom/search";
import { useEffect, useRef, useState } from "react";
import AgentInfoCard from "../queueMonitor/agentInfoCard";
import TableTopBar from "../../modal/tableTopBar";
import { useSelector } from "react-redux";
import { getToken } from "../../../services/api";
import { getENVData } from "../../../config/environment";
function UserPanel() {
  const [sort, setSort] = useState<string>("State");
  const tabs = [
    { name: "State" },
    { name: "Extension" },
    { name: "First Name" },
    { name: "Last Name" },
  ];

  const accessToken = useSelector((state: any) => state.auth.token);

  const [searchtext, setSearchText] = useState<any>("");
  const [counter, setCounter] = useState<number>(0);

  const [eventsData, setEventsData] = useState<any>([]);
  const socketRef = useRef<WebSocket | null>(null);
  // const onHandleSort = (sortBy: string) => {
  // };

  const count: any = [];
  for (let i = 0; i <= 50; i++) {
    count.push(i);
  }

  const onHandleSort = (sortBy: string) => {
    console.log("sortBy", sortBy);
    setSort(sortBy);

    setEventsData((prevEvents: any) => {
      return [...prevEvents].sort((a: any, b: any) => {
        if (sortBy === "State") {
          const getPriority = (event: any) => {
            if (!event.is_logged_in) return 3; // Thirdeeee
            if (event.ph_state === "idle") return 2; // Secondee
            if (event.ph_state === "busy") return 1; //firsteee
            return 4;
          };

          return getPriority(a) - getPriority(b);
        }

        if (sortBy === "Extension") {
          return (a.extension || "").localeCompare(b.extension || "");
        }

        if (sortBy === "First Name") {
          return (a.first_name || "").localeCompare(b.first_name || "");
        }

        if (sortBy === "Last Name") {
          return (a.last_name || "").localeCompare(b.last_name || "");
        }
        return 0;
      });
    });
  };

  // const baseURL = getENVData().baseURL
  useEffect(() => {
    const wssUrl = getENVData().baseURL.replace("https", "wss");
    console.log("wssUrl", wssUrl);
    // Only create the WebSocket if it hasn't been created yet
    if (!socketRef.current) {
      socketRef.current = new WebSocket(
        `${wssUrl}events/liveboard/tenants/${getToken()}/ws/users?token=${accessToken}`
      );

      socketRef.current.addEventListener("open", (event) => {
        console.log("Connected to WebSocket", event);
      });

      socketRef.current.addEventListener("message", (event) => {
        console.log("Message received from server:", event.data);
        const data = JSON.parse(event.data);
        if (data?.event === "user.create") {
          setEventsData((prevEvents: any) => [...prevEvents, data]);
          onHandleSort("State");
        }
        if (data?.event === "user.update") {
          setEventsData((prevEvents: any) =>
            prevEvents.map((event: any) =>
              event.uuid === data.uuid ? { ...event, ...data } : event
            )
          );
          onHandleSort(sort);
        }
        if (data?.event === "user.delete") {
          setEventsData((prevEvents: any) =>
            prevEvents.filter((event: any) => event.uuid !== data.uuid)
          );
        }
      });

      socketRef.current.addEventListener("close", () => {
        console.log("WebSocket connection closed", socketRef.current);
        // alert("WebSocket connection closed");
      });
    }

    // Cleanup WebSocket when the component unmounts
    return () => {
      if (socketRef.current) {
        socketRef.current.close();
        console.log("WebSocket closed during cleanup");
      }
      console.log("socketRef", socketRef);
    };
  }, []);

  const filteredEvents = eventsData.filter((event: any) => {
    const search = searchtext.toLowerCase();

    return (
      (event.extension && event.extension.toLowerCase().includes(search)) ||
      (event.first_name && event.first_name.toLowerCase().includes(search)) ||
      (event.last_name && event.last_name.toLowerCase().includes(search))
    );
  });

  return (
    <div data-testid="userPanel" className="h-full py-3 flex relative gap-2.5">
      <div className=" h-[calc(100vh-125px)]  w-[calc(100vw-120px)] rounded-md bg-[#FFFFFF]">
        <FadeIn>
          {/* Top */}
          <div className="py-5 px-4">
            <TableTopBar
              title={"User Panel"}
              search={true}
              searchtext={searchtext}
              // handleSearch={handleSearch}
              setSearchText={setSearchText}
              // filterTable={filterTable}
            />
          </div>
          {/*  */}
          <div className="flex gap-4 px-8">
            <p className="mt-1.5">Sort by</p>
            {tabs.map((item: any, index: number) => {
              return (
                <div
                  className={`rounded-lg   h-min-[40px]  break-words ${
                    sort === item.name
                      ? "text-[#AB5D0A] font-bold bg-[#FEF4E9]"
                      : "bg-[#EBEBEB80]"
                  } text-center bg-[#EBEBEB80] h-[38px] w-[fit-content] px-3 cursor-pointer hover:bg-[#FEF4E9] hover:text-[#AB5D0A] hover:font-bold flex justify-center items-center`}
                  onClick={() => onHandleSort(item.name)}
                >
                  {item.name}
                </div>
              );
            })}
          </div>
          {/* main body */}

          <div className="w-full mt-4 px-8 grid xl:grid-cols-4 gap-y-[78px] gap-x-4 md:grid-cols-3 sm:grid-cols-1 overflow-visible  max-h-[calc(100vh-270px)]">
            {/* <div className="w-full mt-4 px-8 grid grid-cols-[repeat(auto-fit,minmax(350px,1fr))] gap-5  overflow-visible  max-h-[calc(100vh-270px)]"> */}
            {filteredEvents?.map((node: any) => {
              return (
                <div key={node}>
                  <AgentInfoCard page={"main"} data={node} />
                </div>
              );
            })}
          </div>
          {/*  */}
        </FadeIn>
      </div>
    </div>
  );
}

export default UserPanel;
