import React, { useState, useEffect } from "react";

// Helper function to calculate duration from startTime (which could be a Unix timestamp or ISO string)
const calculateDuration = (startTime) => {
    let startDate;

    if (typeof startTime === "string") {
        startDate = new Date(startTime); // If it's an ISO 8601 string, create Date object
    } else if (typeof startTime === "number") {
        // Check if the timestamp is in milliseconds (more than 1 trillion)
        if (startTime > 1000000000000) {
            startDate = new Date(startTime); // Timestamp is in milliseconds
        } else {
            startDate = new Date(startTime * 1000); // Timestamp is in seconds, convert to milliseconds
        }
    } else {
        throw new Error("Invalid startTime format");
    }

    const now = new Date();

    const diff = now.getTime() - startDate.getTime(); // Duration in milliseconds
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diff % (1000 * 60)) / 1000);

    return `${hours}h ${minutes}m ${seconds}s`;
};

const DurationCounter = ({ startTime }) => {
    const [duration, setDuration] = useState(calculateDuration(startTime));

    useEffect(() => {
        // Set interval to update the duration every second
        const intervalId = setInterval(() => {
            setDuration(calculateDuration(startTime)); // Recalculate and update the duration
        }, 1000);

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, [startTime]); // Re-run effect if startTime changes

    return (
        <div>
            <p>{duration}</p>
        </div>
    );
};

export default DurationCounter;
