import { Module } from "module";

const Constants = {
  maxTextLength: 50, // max text length for TextField
  minTextLength: 3, // min text length for TextField
};
const activityState = {
  IDLE: "idle",
  SILENTCHECK: "silent check",
  LOADING: "loading",
  WAIT: "wait",
  SUCCESS: "success",
  ERROR: "error",
};

const constQueueInfo = [
  {
    name: "Call Count",
    number: "17",
    bgColour: "bg-[#FFEBD5]",
    icon: (
      <svg
        width="60"
        height="60"
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="60" height="60" rx="30" fill="black" fill-opacity="0.2" />
        <path
          d="M46.9571 35.4734L38.492 31.6802L38.4687 31.6694C38.0292 31.4815 37.5498 31.406 37.0739 31.45C36.598 31.4939 36.1405 31.6558 35.7428 31.921C35.696 31.9519 35.651 31.9855 35.6081 32.0216L31.2345 35.7501C28.4637 34.4043 25.6031 31.5652 24.2572 28.8304L27.9911 24.3903C28.027 24.3454 28.0612 24.3004 28.0935 24.2519C28.3531 23.8554 28.5105 23.4008 28.5519 22.9287C28.5933 22.4565 28.5173 21.9815 28.3307 21.5458V21.5243L24.5267 13.0448C24.2801 12.4757 23.856 12.0016 23.3178 11.6933C22.7795 11.385 22.156 11.259 21.5403 11.3342C19.1055 11.6546 16.8706 12.8503 15.2529 14.6981C13.6353 16.5459 12.7456 18.9193 12.75 21.3751C12.75 35.6423 24.3578 47.2501 38.625 47.2501C41.0808 47.2545 43.4543 46.3648 45.302 44.7472C47.1498 43.1296 48.3456 40.8946 48.666 38.4598C48.7413 37.8443 48.6156 37.221 48.3076 36.6828C47.9996 36.1446 47.5259 35.7203 46.9571 35.4734ZM38.625 44.3751C32.5271 44.3685 26.6808 41.9431 22.3689 37.6312C18.057 33.3193 15.6317 27.4731 15.625 21.3751C15.6183 19.6205 16.2504 17.9233 17.4034 16.6007C18.5565 15.278 20.1515 14.4203 21.8907 14.1876C21.89 14.1948 21.89 14.202 21.8907 14.2092L25.6642 22.6545L21.95 27.1C21.9123 27.1433 21.8781 27.1896 21.8476 27.2383C21.5772 27.6533 21.4186 28.131 21.3871 28.6253C21.3556 29.1195 21.4523 29.6135 21.6679 30.0594C23.2959 33.389 26.6506 36.7186 30.0162 38.3448C30.4653 38.5584 30.9622 38.6518 31.4583 38.6159C31.9543 38.58 32.4325 38.416 32.8463 38.14C32.8924 38.1089 32.9368 38.0753 32.9792 38.0393L37.3474 34.3126L45.7928 38.095C45.7928 38.095 45.8071 38.095 45.8125 38.095C45.5827 39.8367 44.7263 41.435 43.4034 42.5908C42.0805 43.7467 40.3818 44.381 38.625 44.3751Z"
          fill="#000C39"
        />
      </svg>
    ),
  },
  {
    name: "Abondoned",
    number: "5",
    bgColour: "bg-[#F5D9D6]",
    icon: (
      <svg
        width="61"
        height="60"
        viewBox="0 0 61 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.666016"
          width="60"
          height="60"
          rx="30"
          fill="black"
          fill-opacity="0.2"
        />
        <path
          d="M30.6641 11.3125C26.968 11.3125 23.355 12.4085 20.2819 14.4619C17.2087 16.5153 14.8135 19.4339 13.3991 22.8486C11.9847 26.2633 11.6146 30.0207 12.3356 33.6458C13.0567 37.2708 14.8365 40.6006 17.45 43.2141C20.0635 45.8276 23.3933 47.6074 27.0183 48.3284C30.6433 49.0495 34.4008 48.6794 37.8155 47.265C41.2302 45.8506 44.1488 43.4554 46.2022 40.3822C48.2556 37.3091 49.3516 33.696 49.3516 30C49.3463 25.0454 47.3758 20.2952 43.8723 16.7917C40.3689 13.2883 35.6187 11.3177 30.6641 11.3125ZM46.4766 30C46.4803 33.6971 45.1818 37.2775 42.8091 40.1128L20.5513 17.8531C22.8602 15.9322 25.6686 14.7087 28.6476 14.3256C31.6266 13.9426 34.6531 14.416 37.3729 15.6903C40.0927 16.9646 42.3933 18.9872 44.0055 21.5214C45.6177 24.0555 46.4749 26.9965 46.4766 30ZM14.8516 30C14.8479 26.3029 16.1463 22.7225 18.519 19.8872L40.7769 42.1469C38.4679 44.0678 35.6596 45.2913 32.6806 45.6744C29.7016 46.0574 26.6751 45.584 23.9552 44.3097C21.2354 43.0354 18.9348 41.0128 17.3226 38.4786C15.7104 35.9445 14.8533 33.0035 14.8516 30Z"
          fill="#000C39"
        />
      </svg>
    ),
  },
  {
    name: "In Queue",
    number: "2",
    bgColour: "bg-[#C8D68F]",
    icon: (
      <svg
        width="61"
        height="60"
        viewBox="0 0 61 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.333984"
          width="60"
          height="60"
          rx="30"
          fill="black"
          fill-opacity="0.2"
        />
        <path
          d="M47.584 25.6875C47.584 26.0687 47.4325 26.4344 47.163 26.704C46.8934 26.9735 46.5277 27.125 46.1465 27.125C45.7652 27.125 45.3996 26.9735 45.13 26.704C44.8604 26.4344 44.709 26.0687 44.709 25.6875V17.6591L32.7903 29.5795C32.5206 29.8493 32.1547 30.0008 31.7733 30.0008C31.3918 30.0008 31.026 29.8493 30.7563 29.5795C30.4865 29.3098 30.335 28.944 30.335 28.5625C30.335 28.181 30.4865 27.8152 30.7563 27.5455L42.6749 15.625H34.6465C34.2652 15.625 33.8996 15.4735 33.63 15.204C33.3604 14.9344 33.209 14.5687 33.209 14.1875C33.209 13.8063 33.3604 13.4406 33.63 13.171C33.8996 12.9015 34.2652 12.75 34.6465 12.75H46.1465C46.5277 12.75 46.8934 12.9015 47.163 13.171C47.4325 13.4406 47.584 13.8063 47.584 14.1875V25.6875ZM40.3965 30C40.0152 30 39.6496 30.1514 39.38 30.421C39.1104 30.6906 38.959 31.0563 38.959 31.4375V44.375H15.959V21.375H28.8965C29.2777 21.375 29.6434 21.2235 29.913 20.954C30.1825 20.6844 30.334 20.3187 30.334 19.9375C30.334 19.5563 30.1825 19.1906 29.913 18.921C29.6434 18.6515 29.2777 18.5 28.8965 18.5H15.959C15.1965 18.5 14.4652 18.8029 13.9261 19.3421C13.3869 19.8812 13.084 20.6125 13.084 21.375V44.375C13.084 45.1375 13.3869 45.8688 13.9261 46.4079C14.4652 46.9471 15.1965 47.25 15.959 47.25H38.959C39.7215 47.25 40.4527 46.9471 40.9919 46.4079C41.5311 45.8688 41.834 45.1375 41.834 44.375V31.4375C41.834 31.0563 41.6825 30.6906 41.413 30.421C41.1434 30.1514 40.7777 30 40.3965 30Z"
          fill="#000C39"
        />
      </svg>
    ),
  },
  {
    name: "Avg. Queue Time",
    number: "00:02:56",
    bgColour: "bg-[#FFDBB2]",
    icon: (
      <svg
        width="60"
        height="60"
        viewBox="0 0 60 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="60" height="60" rx="30" fill="black" fill-opacity="0.2" />
        <path
          d="M30 11.3125C26.304 11.3125 22.6909 12.4085 19.6178 14.4619C16.5446 16.5153 14.1494 19.4339 12.735 22.8486C11.3206 26.2633 10.9505 30.0207 11.6716 33.6458C12.3926 37.2708 14.1725 40.6006 16.786 43.2141C19.3994 45.8276 22.7292 47.6074 26.3543 48.3284C29.9793 49.0495 33.7367 48.6794 37.1514 47.265C40.5661 45.8506 43.4847 43.4554 45.5381 40.3822C47.5915 37.3091 48.6875 33.696 48.6875 30C48.6823 25.0454 46.7117 20.2952 43.2083 16.7917C39.7048 13.2883 34.9546 11.3177 30 11.3125ZM30 45.8125C26.8726 45.8125 23.8154 44.8851 21.2151 43.1476C18.6147 41.4101 16.588 38.9405 15.3912 36.0512C14.1944 33.1618 13.8812 29.9825 14.4913 26.9151C15.1015 23.8478 16.6075 21.0303 18.8189 18.8189C21.0303 16.6075 23.8478 15.1015 26.9151 14.4913C29.9825 13.8812 33.1618 14.1943 36.0512 15.3912C38.9405 16.588 41.4101 18.6147 43.1476 21.215C44.8851 23.8154 45.8125 26.8726 45.8125 30C45.8078 34.1923 44.1403 38.2115 41.1759 41.1759C38.2115 44.1403 34.1923 45.8077 30 45.8125ZM41.5 30C41.5 30.3812 41.3486 30.7469 41.079 31.0165C40.8094 31.2861 40.4438 31.4375 40.0625 31.4375H30C29.6188 31.4375 29.2531 31.2861 28.9835 31.0165C28.714 30.7469 28.5625 30.3812 28.5625 30V19.9375C28.5625 19.5563 28.714 19.1906 28.9835 18.921C29.2531 18.6515 29.6188 18.5 30 18.5C30.3813 18.5 30.7469 18.6515 31.0165 18.921C31.2861 19.1906 31.4375 19.5563 31.4375 19.9375V28.5625H40.0625C40.4438 28.5625 40.8094 28.714 41.079 28.9835C41.3486 29.2531 41.5 29.6188 41.5 30Z"
          fill="#000C39"
        />
      </svg>
    ),
  },
  {
    name: "Max Queue Time",
    number: "00:03:56",
    bgColour: "bg-[#D3D8FF]",
    icon: (
      <svg
        width="61"
        height="60"
        viewBox="0 0 61 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.666016"
          width="60"
          height="60"
          rx="30"
          fill="black"
          fill-opacity="0.2"
        />
        <path
          d="M30.6641 11.3125C26.968 11.3125 23.355 12.4085 20.2819 14.4619C17.2087 16.5153 14.8135 19.4339 13.3991 22.8486C11.9847 26.2633 11.6146 30.0207 12.3356 33.6458C13.0567 37.2708 14.8365 40.6006 17.45 43.2141C20.0635 45.8276 23.3933 47.6074 27.0183 48.3284C30.6433 49.0495 34.4008 48.6794 37.8155 47.265C41.2302 45.8506 44.1488 43.4554 46.2022 40.3822C48.2556 37.3091 49.3516 33.696 49.3516 30C49.3463 25.0454 47.3758 20.2952 43.8723 16.7917C40.3689 13.2883 35.6187 11.3177 30.6641 11.3125ZM30.6641 45.8125C27.5367 45.8125 24.4795 44.8851 21.8791 43.1476C19.2788 41.4101 17.252 38.9405 16.0552 36.0512C14.8584 33.1618 14.5453 29.9825 15.1554 26.9151C15.7655 23.8478 17.2715 21.0303 19.4829 18.8189C21.6944 16.6075 24.5119 15.1015 27.5792 14.4913C30.6465 13.8812 33.8259 14.1943 36.7153 15.3912C39.6046 16.588 42.0742 18.6147 43.8117 21.215C45.5492 23.8154 46.4766 26.8726 46.4766 30C46.4718 34.1923 44.8043 38.2115 41.8399 41.1759C38.8756 44.1403 34.8563 45.8077 30.6641 45.8125ZM42.1641 30C42.1641 30.3812 42.0126 30.7469 41.743 31.0165C41.4735 31.2861 41.1078 31.4375 40.7266 31.4375H30.6641C30.2828 31.4375 29.9172 31.2861 29.6476 31.0165C29.378 30.7469 29.2266 30.3812 29.2266 30V19.9375C29.2266 19.5563 29.378 19.1906 29.6476 18.921C29.9172 18.6515 30.2828 18.5 30.6641 18.5C31.0453 18.5 31.411 18.6515 31.6805 18.921C31.9501 19.1906 32.1016 19.5563 32.1016 19.9375V28.5625H40.7266C41.1078 28.5625 41.4735 28.714 41.743 28.9835C42.0126 29.2531 42.1641 29.6188 42.1641 30Z"
          fill="#000C39"
        />
      </svg>
    ),
  },
  {
    name: "Service Level",
    number: "82%",
    bgColour: "bg-[#FFF2CC]",
    icon: (
      <svg
        width="61"
        height="60"
        viewBox="0 0 61 60"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="0.333984"
          width="60"
          height="60"
          rx="30"
          fill="black"
          fill-opacity="0.2"
        />
        <path
          d="M37.5215 19.9375V42.9375C37.5215 43.3187 37.37 43.6844 37.1005 43.954C36.8309 44.2235 36.4652 44.375 36.084 44.375C35.7027 44.375 35.3371 44.2235 35.0675 43.954C34.7979 43.6844 34.6465 43.3187 34.6465 42.9375V19.9375C34.6465 19.5563 34.7979 19.1906 35.0675 18.921C35.3371 18.6515 35.7027 18.5 36.084 18.5C36.4652 18.5 36.8309 18.6515 37.1005 18.921C37.37 19.1906 37.5215 19.5563 37.5215 19.9375ZM43.2715 11.3125C42.8902 11.3125 42.5246 11.464 42.255 11.7335C41.9854 12.0031 41.834 12.3688 41.834 12.75V42.9375C41.834 43.3187 41.9854 43.6844 42.255 43.954C42.5246 44.2235 42.8902 44.375 43.2715 44.375C43.6527 44.375 44.0184 44.2235 44.288 43.954C44.5575 43.6844 44.709 43.3187 44.709 42.9375V12.75C44.709 12.3688 44.5575 12.0031 44.288 11.7335C44.0184 11.464 43.6527 11.3125 43.2715 11.3125ZM28.8965 25.6875C28.5152 25.6875 28.1496 25.839 27.88 26.1085C27.6104 26.3781 27.459 26.7438 27.459 27.125V42.9375C27.459 43.3187 27.6104 43.6844 27.88 43.954C28.1496 44.2235 28.5152 44.375 28.8965 44.375C29.2777 44.375 29.6434 44.2235 29.913 43.954C30.1825 43.6844 30.334 43.3187 30.334 42.9375V27.125C30.334 26.7438 30.1825 26.3781 29.913 26.1085C29.6434 25.839 29.2777 25.6875 28.8965 25.6875ZM21.709 32.875C21.3277 32.875 20.9621 33.0264 20.6925 33.296C20.4229 33.5656 20.2715 33.9313 20.2715 34.3125V42.9375C20.2715 43.3187 20.4229 43.6844 20.6925 43.954C20.9621 44.2235 21.3277 44.375 21.709 44.375C22.0902 44.375 22.4559 44.2235 22.7255 43.954C22.995 43.6844 23.1465 43.3187 23.1465 42.9375V34.3125C23.1465 33.9313 22.995 33.5656 22.7255 33.296C22.4559 33.0264 22.0902 32.875 21.709 32.875ZM14.5215 40.0625C14.1402 40.0625 13.7746 40.2139 13.505 40.4835C13.2354 40.7531 13.084 41.1188 13.084 41.5V42.9375C13.084 43.3187 13.2354 43.6844 13.505 43.954C13.7746 44.2235 14.1402 44.375 14.5215 44.375C14.9027 44.375 15.2684 44.2235 15.538 43.954C15.8075 43.6844 15.959 43.3187 15.959 42.9375V41.5C15.959 41.1188 15.8075 40.7531 15.538 40.4835C15.2684 40.2139 14.9027 40.0625 14.5215 40.0625Z"
          fill="#000C39"
        />
      </svg>
    ),
  },
];
export { Constants, constQueueInfo, activityState };
