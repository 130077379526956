import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Notification from "../../atom/Notification";
import Modal from "../../atom/modal";
import ComponentMap from "../../atom/componentmap";
import ThemedButton from "../../atom/ThemedButton/themedButton";
import { useDispatch, useSelector } from "react-redux";
import { actualDataToMapData } from "../../services/utilities";
import {
  editProvisionUser,
  getTelcoId,
  getTenantUserDetails,
  resetPassword,
} from "../../services/api";
import { setCurrentUserData } from "../../toolkit/reducers/userDataSlice";
import { getENVData } from "../../config/environment";
import {
  setPermissionStatus,
  setPermissions,
} from "../../toolkit/reducers/permissionSlice";
import { useAuth } from "../authv2/authClient";

function UserProfile(props: any) {
  const { userProfileModal, setUserProfileModal } = props;
  const [buttonLoader, setButtonLoader] = useState<boolean>(false);
  const [passwordChange, setPasswordChange] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const navigateTo = (nav: string) => {
  //   console.log(nav, "nav");
  //   navigate(`/app/${nav}`, { replace: true });
  // };

  const userData = useSelector((state: any) => state.currentUserData.data);
  const auth = useAuth();

  const defaultValue = [
    {
      title: "Email Address",
      name: "email",
      type: "textbox",
      value: "johndoe@gmail.com",
      disabled: true,
    },
    {
      title: "First Name",
      name: "firstname",
      type: "textbox",
      value: "John",
      layout: 3,
      required: true,
      errormessage: "First Name is Required",
    },
    {
      title: "Last Name",
      name: "lastname",
      type: "textbox",
      value: "Doe",
      layout: 3,
      required: true,
      errormessage: "Last Name is Required",
    },
    {
      title: "Extension",
      name: "extension",
      value: "",
      type: "number",
      layout: 3,
      minLength: 3,
      required: true,
      errormessage: "Extension is Required",
    },
    {
      title: "DID",
      name: "did",
      type: "number",
      value: "",
      minLength: 10,
      maxLength: 12,
      layout: 3,
      required: true,
      errormessage: "Did is Required",
    },
  ];
  const [profileData, setProfileData] = useState([...defaultValue]);
  const [showPasswordField, setShowPasswordField] = useState<boolean>(false);
  const [passwordData, setPasswordData] = useState([
    {
      title: "New Password",
      name: "newpassword",
      type: "password",
      value: "",
      hidden: false,
      layout: 6,
    },
    {
      title: "Confirm New Password",
      name: "confirmpassword",
      type: "password",
      value: "",
      hidden: false,
      layout: 6,
    },
  ]);
  const onSaveChanges = (result: any) => {
    setButtonLoader(true);
    console.log(result, "result");
    let apiError = false;
    console.log("passwordData", passwordData);
    if (passwordData[0].value !== "" || passwordData[1].value !== "") {
      if (passwordData[0].value !== passwordData[1].value) {
        Notification("Failed", "Password Mismatched!", "danger");
        setButtonLoader(false);
        // setUserProfileModal(false);
        apiError = true;
      } else {
        let payload = {
          email: userData?.email,
          password: passwordData[0].value,
        };
        resetPassword(payload, userData?.uuid).then((resultData: any) => {
          console.log("resultData", resultData);
          if (!resultData?.success) {
            apiError = true;
          } else {
            if (getENVData().env === "localhost") {
              localStorage.clear();
            }
            // await keycloak.logout();
            auth.signOut(() => {});
            sessionStorage.removeItem("simulation");
            // cookies.remove('simulation', { path: '/' })
            dispatch(setPermissions({}));
            dispatch(setPermissionStatus(0));
            navigate(`/app`, { replace: false });
          }
          // const result: any = resultData.data;
        });
      }
    }
    if (!apiError) {
      editProvisionUser(result, userData.tenant, userData.uuid)
        .then((resultData: any) => {
          const result: any = resultData.data;
          console.log(result, "result");
          if (result?.data?.parameter || result === undefined) {
            // setUserProfileModal(false);
            setButtonLoader(false);
            let msg = result?.data?.reason;
            Notification(
              "Failed",
              msg?.charAt(0).toUpperCase() + msg.slice(1),
              "danger"
            );
          } else {
            Notification("Success", "Updated successfully", "success");
            setButtonLoader(false);
            setUserProfileModal(false);
            storeUserDetails();
          }
        })
        .catch((error: any) => {
          setButtonLoader(false);
          Notification("Error", "Update Failed", "danger");
          // setUserProfileModal(false);
        });
    }
  };

  const storeUserDetails = () => {
    getTenantUserDetails("").then((resultData: any) => {
      const result: any = resultData.data;
      console.log(result, "userdetails");
      dispatch(setCurrentUserData(result));
    });
  };
  useEffect(() => {
    setProfileData(actualDataToMapData([...profileData], userData));
    console.log("fghj", getTelcoId());
  }, [userData]);

  return (
    <>
      <Modal
        // icon={'custom'}
        title={"User Profile"}
        closeEvent={() => setUserProfileModal(false)}
      >
        <div className="mt-4 relative w-[540px] smallPicker">
          <ComponentMap data={profileData} setData={setProfileData} />
          <div className="h-[56px] flex justify-start px-5 items-center float-right">
            <p
              className="mb-3 text-[#0326AB] cursor-pointer hover:underline"
              onClick={() => setShowPasswordField(!showPasswordField)}
            >
              {" "}
              {showPasswordField ? "Hide Change Password" : "Change Password"}
            </p>

            {/* <ThemedButton
                children={"Change Password"}
                icon="custom"
                theme="secondary"
                click={onSaveChanges}
              /> */}
          </div>
        </div>
        {showPasswordField && (
          <div className="mt-8 relative w-[540px] smallPicker">
            <ComponentMap data={passwordData} setData={setPasswordData} />
          </div>
        )}
        <div className=" w-full h-full flex items-baseline justify-end mt-8 -ml-1">
          <ThemedButton
            children={"Save Update"}
            icon="custom"
            theme="primary"
            valiData={profileData}
            change={setProfileData}
            click={onSaveChanges}
            loading={buttonLoader}
          />
        </div>
      </Modal>
    </>
  );
}

export default UserProfile;
