import { useEffect, useState } from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { useTranslation } from "react-i18next";
import { useTheme } from "../theme/themeContext";

const CheckboxLabels = (props) => {
  const { node, data, change } = props;
  const { theme, getCurrentThemeJSON } = useTheme();
  const [themeJSON, setTheme] = useState({});
  const { t } = useTranslation();

  const setChange = (value) => {
    let updateData = [...data];
    let filter;

    filter = updateData.find((n) => {
      return n.name === node.name;
    });
    if (filter) {
      if (filter?.childrens) {
        let temp = filter.childrens.find((nd) => {
          const item1 = node.displayProps
            ? nd[node.displayProps.label]
            : nd.option
            ? nd.option
            : nd;
          const item2 = node.displayProps
            ? value[node.displayProps.label]
            : value.option
            ? value.option
            : value;
          return item1 === item2;
        });
        if (temp) {
          temp.value = !temp.value;
        }
      } else {
        filter.value = !filter.value;
        if (filter.reference) {
          filter?.reference.items.map((item) => {
            let refData = updateData.find((n) => {
              return n.name === item;
            });
            if (refData) {
              if (filter.reference.condition.operator === "=") {
                refData[filter.reference.action] = filter.value;
              } else if (filter.reference.condition.operator === "!=") {
                refData[filter.reference.action] = !filter.value;
              }
            }
          });
        }
      }
      if (filter.childrens) {
        filter.value = [];
        filter.childrens.map((item) => {
          const value = node.displayProps
            ? item[node.displayProps.value]
            : item.value;
          if (item?.value === true) {
            filter.value.push(value);
          }
        });
      }
    }

    change([...updateData]);
  };
  useEffect(() => {
    getCurrentThemeJSON(theme, setTheme);
  }, []);
  return (
    <div className="flex px-2">
      <FormGroup className="flex ">
        {/* <div className="text-xs text-ternary-text	 mb-[-8px]">{t(node.title)}</div> */}

        {node.childrens ? (
          node.childrens.map((n, key) => {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    style={{
                      color: themeJSON.ternaryText,
                    }}
                    checked={n.value}
                    disabled={node.disabled}
                  />
                }
                onChange={(e) => setChange(n)}
                label={t(
                  node.displayProps
                    ? n[node.displayProps.label]
                    : n.option
                    ? n.option
                    : n
                )}
                style={{
                  color: themeJSON.ternaryText,
                }}
                key={key}
                value={
                  node.displayProps
                    ? n[node.displayProps.value]
                    : n.value
                    ? n.value
                    : n
                }
              />
            );
          })
        ) : (
          <FormControlLabel
            style={{
              color: themeJSON.ternaryText,
            }}
            onChange={(e) => setChange(node)}
            value={node.value}
            disabled={node?.disabled}
            control={
              <Checkbox
                checked={node.value}
                style={{
                  color: "#F78F1E",
                }}
              />
            }
            label={t(node.title)}
          />
        )}
      </FormGroup>
    </div>
  );
};

export default CheckboxLabels;
