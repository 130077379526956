import { useState } from "react";
import { useSelector } from "react-redux";
import Wallboard from "../wallboard/wallboard";
import UserPanel from "../userPanel/userPanel";
import ComponentMap from "../../../atom/componentmap";
import ThemedButton from "../../../atom/ThemedButton/themedButton";
import QueueInfo from "./queueInfo";
import AgentStatus from "./agentStatus";
import {
  BackIcon,
  CloseIcon,
  DownArrow,
  FilterIcon,
  GoBackIcon,
  UpArrow,
  UserIcon,
} from "../../../utils/icons/defaultIcons";
import AgentInfoCard from "./agentInfoCard";
import WallboardSettings from "../wallboard/wallboardSettings";
import FadeIn from "react-fade-in/lib/FadeIn";

function QueueMonitor() {
  const [dropdownSelect, setDropdownSelect] = useState<boolean>(false);
  const [submit, setSubmit] = useState<boolean>(false);

  const activeLiveBoard = useSelector(
    (state: any) => state.ActiveBoard.activeMenu
  );

  const [queueGroupData, setQueueGroupData] = useState<any>([
    {
      title: "Queue Group",
      name: "queue",
      value: "",
      type: "dropdown",
      displayProps: { label: "label", value: "uuid" },
      childrens: [{ label: "Kev Test Thang", value: "Kev Test Thang" }],
    },
  ]);

  const text = [
    {
      queueInfo:
        "The main Queue Info window will show you the groups extension and name, as well as some statistics calculated for the last hour (last hour by default: different options are available upon launching). These are the meanings of each of the numbers.",
    },
    {
      agentInfo:
        "Agent info tells you how many agents are in the queue, how many of them are logged into the queue, and how many arecurrently on the phone. Please note that the On Phone count includes agents that may be on a call, but not logged into the queue group.",
    },
    {
      specificAgentInfo:
        "All agents in the queue will have an info-block, showing the Logged-In status, the Busy status and the Extension. The info-block which can be expanded by clicking to + sign on  the right side. This will show Current Activity and Activity  Summary If the agent is busy, Current Activity will show the  Calling Number, Dialed Number, and Start Time of the call the agent is currently on. Please note that this is real-time information, and may not a call related to / involving the Queue Group. Activity Summary provides a Call Count, Talk Time, and Average Talk Time for the agent for calls within the selected time frame. Please note these numbers are only of calls where the Queue Group was involved at some point in duration the call, and not an overall overview of the agent.",
    },
  ];

  const onSubmit = (result: any) => {
    setSubmit(true);
  };

  const count: any = [];
  for (let i = 0; i <= 10; i++) {
    count.push(i);
  }
  return (
    <div data-testid="queueMonitor">
      {activeLiveBoard === 1 ? (
        <WallboardSettings />
      ) : activeLiveBoard === 2 ? (
        <UserPanel />
      ) : (
        <div className=" h-full w-[calc(100vw-120px)] py-3 flex relative gap-2.5">
          {/* Side Div */}

          <div className="h-[calc(100vh-125px)] w-[15%] bg-[#FFFFFF] rounded-md px-1 py-2 space-y-2  ">
            <div className="flex py-4 px-2">
              {submit && (
                <div
                  className="cursor-pointer -ml-2"
                  onClick={() => {
                    setSubmit(false);
                  }}
                >
                  <BackIcon />
                </div>
              )}
              <div className="font-bold text-[16px] ">Launch Queue Monitor</div>
            </div>

            <ComponentMap data={queueGroupData} setData={setQueueGroupData} />
            <div className="text-right px-1">
              <ThemedButton
                children={"Submit"}
                theme={"primary"}
                click={onSubmit}
                change={setQueueGroupData}
                valiData={queueGroupData}
              />
            </div>
          </div>
          {/*  */}
          {/* main div */}
          <div className="h-[calc(100vh-125px)] w-[85%]  rounded-md overflow-auto  ">
            <FadeIn>
              {submit ? (
                <div className="bg-[#fff] rounded-md h-[calc(100vh-125px)] overflow-auto ">
                  <FadeIn>
                    <div className="flex  p-4 mt-3 justify-between">
                      <p className="font-bold text-[18px]">
                        {queueGroupData[0].childrens[0].value}
                      </p>
                      <div className="cursor-pointer">
                        <FilterIcon />
                      </div>
                    </div>

                    {/* queue info */}
                    <div className="p-5">
                      <p className="font-bold text-[24px]">Queue Info</p>
                      <div className="mt-2  rounded-lg ">
                        <QueueInfo />
                      </div>
                      {/*  */}

                      {/* agent status */}
                      <div className=" mt-6 rounded-lg">
                        <p className="font-bold text-[24px]">Agent Status</p>
                        <div className="-mt-3">
                          <AgentStatus col={"3"} width={"w-[462px]"} />
                        </div>
                      </div>
                      {/*  */}

                      <div className=" mt-4 px-0 grid xl:grid-cols-4 gap-2 md:grid-cols-3 sm: grid-cols-1 ">
                        {count?.map((node: any) => {
                          return (
                            <div key={node}>
                              <AgentInfoCard page={"main"} />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </FadeIn>
                </div>
              ) : (
                <>
                  <div className="bg-[#fff] rounded-lg">
                    <p className="font-bold text-[24px] p-4">
                      Queue Monitoring Information
                    </p>
                    <div className="px-4 space-y-4">
                      <p className="text-[18px]">
                        Below you will find an example of the information you
                        will see in Queue Monitor.
                      </p>
                      <p className="text-[18px]">{text[0]?.queueInfo}</p>
                    </div>
                    <div className="flex justify-center w-full h-max mt-4">
                      <div className="w-[100%]  py-2 ml-4">
                        {/* <div className="mt-2 border border-[#0000001F] rounded-lg "> */}
                        <QueueInfo />
                        {/* </div>*/}
                      </div>
                    </div>
                  </div>

                  <div className="w-full h-[calc(100vh-280px)] mt-2.5 p-0 gap-x-2.5 rounded-lg flex">
                    {/* left side */}
                    <div className=" basis-[50%]  rounded-lg p-2 px-4 bg-[#fff]">
                      <p className="font-bold text-[24px] bg-[#]">Agent Info</p>
                      <div className="-mt-2">
                        <AgentStatus col={"1"} />
                      </div>
                      <p className="mt-5 bg-[#F7EFE7] rounded-lg p-5 text-[18px]">
                        {text[1]?.agentInfo}
                      </p>
                    </div>
                    {/*  */}

                    {/* right side */}
                    <div className=" basis-[50%] rounded-lg  p-2 px-4   bg-[#fff]">
                      <p className="font-bold text-[24px]">
                        Specific Agent Info
                      </p>
                      {/* agent box */}
                      <div className="w-[100%]">
                        <AgentInfoCard page={"info"} />
                      </div>
                      {/*  */}
                      <p className="mt-5 text-[18px] p-5 bg-[#F7EFE7] rounded-lg">
                        {text[2]?.specificAgentInfo}
                      </p>
                    </div>
                    {/*  */}
                  </div>
                </>
              )}
            </FadeIn>
          </div>
          {/*  */}
        </div>
      )}
    </div>
  );
}

export default QueueMonitor;
